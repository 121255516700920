import { ConsoleSqlOutlined } from "@ant-design/icons";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import { urlAssignLabelFile, urlCreateUserSiteSubCategory, urlDeleteUserSiteSubCategory, urlGetUserSiteSubCategoryList, urlUpdateUserSiteSubCategory, urlUpdateUserSiteSubCategoryPosition } from "./CONSTANTS";

export function apiCreateSubCategory(data) {
  const url = urlCreateUserSiteSubCategory;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("keyword", data.keyword);
  formData.append("name", data.name);
  formData.append("status", data.status);
  formData.append("file_link", data.file_link);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export function apiUpdateSubCategory(data) {
  const url = urlUpdateUserSiteSubCategory;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", data.id);
  formData.append("name", data.name);
  formData.append("status", data.status);
  formData.append("file_link", data.file_link);
  formData.append("keyword", data.keyword);

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export function apiAssignLabelFile(id, idList) {
  const url = urlAssignLabelFile;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  formData.append("idList", JSON.stringify(idList));

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export function apiUpdateSubCategoryPosition(dataList) {
  const url = urlUpdateUserSiteSubCategoryPosition;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var idList = []
  for (let i = 0; i<dataList.length; i++) {
    idList.push({id : dataList[i]['id']})
  }
  var formData = new FormData();
  formData.append("dataList", JSON.stringify(idList));
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export const apiGetSubCategoryList = () => {
  const url = urlGetUserSiteSubCategoryList;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export function apiDeleteSubCategory(id) {
  const url = urlDeleteUserSiteSubCategory;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}
