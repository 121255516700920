import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { NotFound } from "navigation/NotFound";
import {
  ROUTE_LOGIN,
  ROUTE_REGISTER,
  ROUTE_DASHBOARD,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_PROFILE,
  ROUTE_LABELS,
  ROUTE_FILES,

} from "navigation/CONSTANTS";
import PrivateRoute from "./Auth/PrivateRoute";
import LoginPage from "pages/LoginPage/LoginPage";
import RegisterPage from "pages/RegisterPage/RegisterPage";
import ForgotPasswordPage from "pages/ForgotPasswordPage/ForgotPasswordPage";
import TopbarLayout from "layouts/TopbarLayout/TopbarLayout";
import DashboardPage from "pages/DashboardPage/Dashboard";
import SidebarLayout from "layouts/SidebarLayout/SidebarLayout";
import { useDispatch } from "react-redux";
import { ChangeSize } from "redux/actions/appActions";
import MainLayout from "layouts/MainLayout/MainLayout";
import UserProfilePage from "pages/ProfilePage/UserProfile/UserProfilePage";
import U_MainCategoryPage from "pages/UserSiteItemCategoryPage/U_MainCategoryPage/U_MainCategoryPage";
import U_SubCategoryPage from "pages/UserSiteItemCategoryPage/U_SubCategoryPage/U_SubCategoryPage";
export const RouterConfig = () => {
  const screenSize = useWindowSize();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ChangeSize({ width: screenSize.width, height: screenSize.height }))
  }, [screenSize]);

  return (
    <div>
      <Switch>
        <Route exact path={ROUTE_LOGIN} component={LoginPage} />
        <Route exact path={"/"} component={LoginPage} />
        <Route path={ROUTE_REGISTER} component={RegisterPage} />
        <Route path={ROUTE_FORGOT_PASSWORD} component={ForgotPasswordPage} />

        <SidebarLayout>
          <TopbarLayout>
            <MainLayout>
              <Switch>
                <PrivateRoute
                  exact
                  path={ROUTE_DASHBOARD}
                  component={DashboardPage}
                />
                
                <PrivateRoute
                  exact
                  path={ROUTE_PROFILE}
                  component={UserProfilePage}
                />
                
                <PrivateRoute
                  exact
                  path={ROUTE_LABELS}
                  component={U_MainCategoryPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_FILES}
                  component={U_SubCategoryPage}
                />
                {/* List a generic 404-Not Found route here */}
                <Route path="*" component={NotFound} />
              </Switch>
              </MainLayout>
          </TopbarLayout>
        </SidebarLayout>


      </Switch>

    </div>
  );
};




// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
