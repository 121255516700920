import React from 'react';
import "./TopWidget.css"
import { Row, Col } from "antd";

import { ReactComponent as storeIcon } from '../../../assets/icons/ic_store.svg';
import { ReactComponent as promotionIcon } from '../../../assets/icons/ic_promotion.svg';
import { ReactComponent as productIcon } from '../../../assets/icons/ic_product.svg';
import { ReactComponent as followerIcon } from '../../../assets/icons/ic_follower.svg';
import CategoryItemWidget from './CategoryItemWidget/CategoryItemWidget';
import { useHistory } from 'react-router';
import { ROUTE_PROFILE, ROUTE_LABELS, ROUTE_FILES } from 'navigation/CONSTANTS';
import { useDispatch } from 'react-redux';
import { UpdateCurrentRoot } from 'redux/actions/appActions';

const TopWidget = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    return (
        <div>
            <Row align={'middle'} gutter={32}>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <CategoryItemWidget
                        icon={storeIcon}
                        title="You will setup Label List"
                        count={''}
                        type="Label List"
                        onClick={() => {
                            dispatch(UpdateCurrentRoot({ currentRoute: ROUTE_LABELS}))
                            history.push(ROUTE_LABELS)
                        }}
                    />
                </Col>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <CategoryItemWidget
                        icon={promotionIcon}
                        title="You will setup File List"
                        count={''}
                        type="File List"
                        onClick={() => {
                            dispatch(UpdateCurrentRoot({ currentRoute: ROUTE_FILES}))
                            history.push(ROUTE_FILES)
                        }}
                    />
                </Col>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <CategoryItemWidget
                        icon={followerIcon}
                        title="Super Admin Setup"
                        count={''}
                        type="Profile"
                        onClick={() => {
                            dispatch(UpdateCurrentRoot({ currentRoute: ROUTE_PROFILE}))
                            history.push(ROUTE_PROFILE)
                        }}
                    />
                </Col>

            </Row>
        </div>
    );
};

export default TopWidget;