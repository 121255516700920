// @flow strict

import React from "react";
import "./AuthLogoWidget.css";
import { logoUrl } from "config/CONSTANTS";
export const AuthLogoWidget = () => {
  return (
    <div>
      <div className="auth-logo-widget">
        <img src={logoUrl} style={{ maxWidth: "90%", maxHeight: 150 }} />
        <div className="auth-logo-widget-description">
          Document Libaray
        </div>
      </div>
    </div>
  );
};

export default AuthLogoWidget;
