import React, { useEffect, useState } from 'react'
import { confirmable } from 'react-confirm'
import { message, Modal, Col, Input, Row, DatePicker, Select, Spin, Switch } from "antd";

import "./AddSubCategoryDialog.css"
import { isEmpty } from 'utils/GlobalFunctions';
import ProductImageWidget from './ProductImageWidget';
import moment from "moment"
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import ProductInputNumber from 'components/InputWidgets/InputNumber/ProductInputNumber';
import ProductDescriptionInput from 'components/ProductPage/ProductDescriptionInput/ProductDescriptionInput';
import ProductInputMultiText from 'components/InputWidgets/InputMultiText/ProductInputMultiText';
const { Option } = Select;
const { RangePicker } = DatePicker;

function AddSubCategoryDialog(props) {
  const { show, proceed, record, title, isEditing } = props

  const [data, setData] = useState({});
  const [errorField, setErrorField] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {

  }, [])

  useEffect(() => {
    if (record !== undefined) {
      setData({ ...record });
    } else {
      setData({
        "status": "1", "keyword": ""
      });
    }
  }, [record]);

  const onViewMode = (checked) => {
    setData({
      ...data, "status": checked === true ? "1" : "0"
    });
  }

  const validateFields = async () => {
    var errorList = Array();
    errorList = isEmpty(data, "name", errorList);
    errorList = isEmpty(data, "file_link", errorList);
    errorList = isEmpty(data, "keyword", errorList);
    setErrorField([...errorList]);
    return errorList.length == 0;
  };


  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-add-sub-category-dlg"
      onOk={async () => {

        if ((await validateFields()) === false) return;

        proceed({
          ...data,
        });
      }}
    >
      <Spin spinning={isBusy}>

        <div>
          <Row align={"middle"} gutter={16} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={20}>
              <div>File Name*</div>
              <ProductDescriptionInput
                placeholder="File Name"
                field={"name"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={4}>
              <Switch
                checkedChildren="Show"
                unCheckedChildren="Hide"
                onChange={onViewMode}
                checked = {data["status"] === "1" ? true:false}
                style={{ marginTop: "10px" }}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div>File Link*</div>
              <ProductDescriptionInput
                placeholder="File Link"
                field={"file_link"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div>Keyword for file search*</div>
              <ProductInputMultiText
                placeholder="Keyword"
                field={"keyword"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
              />
            </Col>
          </Row>




        </div>
      </Spin>
    </Modal>
  );
}

export default confirmable(AddSubCategoryDialog)
