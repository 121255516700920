import { googleKey } from "config/CONSTANTS";

//export const BASE_URL               = "http://127.0.0.1/WaitIndustryDocumentSystem";
export const BASE_URL               = "https://library.wait.industries/backend/WaitIndustryDocumentSystem";

//Admin backend base url
export const BASE_URL_ADMIN         = BASE_URL + "/admin";

export const urlUserLogin           = BASE_URL_ADMIN + "/main/login";


export const urlGetProfileInfo      = BASE_URL_ADMIN + "/Profile/GetProfileInfo";
export const urlUpdateProfileInfo   = BASE_URL_ADMIN + "/Profile/UpdateProfileInfo";

export const urlGetDashboardDetail  = BASE_URL_ADMIN + "/DashBoard/GetDashboardDetail";

// //User backend base url
// export const BASE_URL_USER                  = BASE_URL + "/user";

// export const urlGetInternalUseForUser       = BASE_URL_USER + "/DashBoard/getInternalUse";
// export const urlGetPartListForUser          = BASE_URL_USER + "/DashBoard/getPartList";


export const urlCreateUserSiteMainCategory = BASE_URL_ADMIN + "/FolderList/createFolder";
export const urlUpdateUserSiteMainCategory = BASE_URL_ADMIN + "/FolderList/updateFolder";
export const urlGetUserSiteMainCategoryList = BASE_URL_ADMIN + "/FolderList/getFolderList";
export const urlDeleteUserSiteMainCategory = BASE_URL_ADMIN + "/FolderList/deleteFolder";
export const urlUpdateUserSiteMainCategoryPosition = BASE_URL_ADMIN + "/FolderList/updateFolderPosition";


export const urlCreateUserSiteSubCategory = BASE_URL_ADMIN + "/FileList/createFile";
export const urlUpdateUserSiteSubCategory = BASE_URL_ADMIN + "/FileList/updateFile";
export const urlAssignLabelFile           = BASE_URL_ADMIN + "/FileList/assignLabelFile";

export const urlGetUserSiteSubCategoryList = BASE_URL_ADMIN + "/FileList/getFileList";
export const urlDeleteUserSiteSubCategory = BASE_URL_ADMIN + "/FileList/deleteFile";
export const urlUpdateUserSiteSubCategoryPosition = BASE_URL_ADMIN + "/FileList/updateFilePosition";
