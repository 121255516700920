import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import CommonDivWidget from 'components/CommonDivWidget/CommonDivWidget'
import { message, Spin, DatePicker, Row, Col } from 'antd';
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import { TitleSearch } from 'components/SearchWidget/TitleSearch';
import { useHistory } from 'react-router-dom';
import { UpdateCurrentRoot } from 'redux/actions/appActions';
import TableWidget from './TableWidget/TableWidget';
import { ROUTE_FILES } from 'navigation/CONSTANTS';
import { showAddMainCategoryDialog } from './AddMainCategoryDialog/showAddMainCategoryDialog';
import { apiCreateMainCategory, apiDeleteMainCategory, apiGetMainCategoryList, apiUpdateMainCategory } from 'services/userSiteMainCategoryService';
const { RangePicker } = DatePicker;

function U_MainCategoryPage() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isBusy, setIsBusy] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [searchList, setSearchList] = useState([]);

    useEffect(() => {
        setIsBusy(true);
        let mounted = true;
        setTimeout(() => {
          if (mounted) {
            apiGetMainCategoryList()
                .then((data) => {
                    setSearchList(data)
                    setDataList(data)
                    setIsBusy(false);
                })
                .catch((err) => {
                    setIsBusy(false);
                    message.error("Sorry! " + err);
                });
          }
        }, 50);
      
        return () => mounted = false;
    }, []);

    const handleSearch = (searchText) => {
        const tmpList = dataList.filter((info) => info.name.toLowerCase().includes(searchText.toLowerCase()))
        setSearchList([...tmpList])
    };
    const handleChange = (event) => {
        if (event.target.value === "") {
            setSearchList(dataList)
        }
        else {
            handleSearch(event.target.value)
        }
    };
    const onNewMainCategory = async () => {
        var res = await showAddMainCategoryDialog({
            title: "Create New Folder",
            isEditing: false
        });
        if (res === null) return;
        setIsBusy(true)
        apiCreateMainCategory(res)
            .then((data) => {
                setIsBusy(false);
                if (data === null) {
                    message.error("There's something wrong while Create New Folder");
                }
                var tmpData = dataList;
                let updateData = [...dataList];
                updateData.splice(tmpData.length, 0, data);
                tmpData = [...updateData];
                setDataList(tmpData)
                setSearchList(tmpData)
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Error! " + err);
            });
    }
    const onClickDelete = (pInfo) => {
        setIsBusy(true);
        apiDeleteMainCategory(pInfo.id)
            .then((data) => {
                setIsBusy(false);
                let updateData = [...dataList]
                var storeIndex = updateData.findIndex(x => x.id == pInfo.id);
                updateData.splice(storeIndex, 1);
                var tmpData = [...updateData];
                setDataList(tmpData);
                setSearchList(tmpData);
            })
            .catch((err) => {
                setIsBusy(false);
                message.error(err);
            });
    }
    const onClickDetail = (pInfo) => {
        // dispatch(UpdateCurrentRoot({ currentRoute: ROUTE_FILES }))
        // history.push(ROUTE_FILES + "/" + pInfo.id)
    }
    const onClickEdit = async (pInfo) => {
        let info = pInfo
        var res = await showAddMainCategoryDialog({
            title: "Edit New Folder",
            record: info,
            isEditing: true
        });
        if (res === null) return;

        setIsBusy(true);
        apiUpdateMainCategory(res)
            .then((data) => {
                setIsBusy(false);
                if (data === null) {
                    message.error("There's something wrong while Edit New Folder");
                }
                let updateData = [...dataList];
                var storeIndex = updateData.findIndex(x => x.id == data.id);
                updateData.splice(storeIndex, 1, data);
                setDataList(updateData)
                setSearchList(updateData)

            })
            .catch((err) => {
                setIsBusy(false);
                message.error(err);
            });
    }
    const handleDateFilter = (startDate, endDate) => {
        if (startDate == "" || endDate == "") {
            setSearchList([...dataList])
        } else {
            const start_date = new Date(startDate + "T00:00:00");
            const end_date = new Date(endDate + "T23:59:59");
            const tmpList = dataList.filter((info) => start_date <= new Date(info.update_timestamp.replace(/ /g, "T")) && new Date(info.update_timestamp.replace(/ /g, "T")) <= end_date)
            setSearchList([...tmpList])
        }
    }
    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>
                <h2>Label List</h2>
                <Row>
                    <Col>
                        <div style={{ fontFamily: 'mediumFont', padding: 20 }}>Date Filter Period : &nbsp;&nbsp;
                            <RangePicker size={'default'}
                                onChange={(e, date) => {
                                    if (date == null) {
                                        handleDateFilter("", "")
                                    } else {
                                        handleDateFilter(date[0], date[1])
                                    }

                                }} />
                        </div>
                    </Col>
                    <Col flex={'auto'}>
                        <div style={{ fontFamily: 'mediumFont', padding: 20 }}>
                            <TitleSearch
                                onSearch={handleSearch}
                                onChange={handleChange}
                                onPressEnter={handleSearch}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div style={{ fontFamily: 'mediumFont', padding: 20 }}>
                            <CommonButton onClick={() => {
                                onNewMainCategory()
                            }}>New</CommonButton>
                        </div>
                    </Col>
                </Row>

                <div>
                    <TableWidget
                        dataList={searchList}
                        onDetailAction={onClickDetail}
                        onUpdateAction={onClickEdit}
                        onDeleteAction={onClickDelete}
                    />
                </div>
            </Spin>
        </CommonDivWidget>
    )
}

export default U_MainCategoryPage
