import * as React from "react";

import { Input  } from "antd";
const { TextArea } = Input;

function ProductInputMultiText(props) {
  const { productInfo, setProductInfo } = props;
  const { onChangeHandle, field } = props;
  const { errorField, setErrorField } = props;
  return (
    <div>
      <TextArea
        style={{
          border: errorField !== undefined && errorField.includes(field)
            ? "1px solid red"
            : "1px solid #d9d9d9",
          borderRadius: "8px",
          background: `var(--backgroundColor)`,
          padding: "4px 12px",
          width: "100%"
        }}
        rows={5}
        value={
          productInfo === undefined || productInfo[field] == undefined ? "" : productInfo[field]
        }
        onChange={(e) => {
          var info = productInfo;
          info[field] = e.target.value;
          if (errorField !== undefined && errorField.includes(field)) {
            var errors = errorField.filter((x) => x != field);
            // console.log(errors);
            setErrorField([...errors]);
          }
          setProductInfo({ ...info });
          if(onChangeHandle !== undefined){
            onChangeHandle(e.target.value)
          }
        }}
      />
    </div>
  );
}

export default ProductInputMultiText;
